








import { Component, Vue } from "vue-property-decorator";
import Concejo from "@/components/Municipalidad/Concejo.vue";

@Component({
  components: {
    Concejo
  }
})
export default class ConcejoView extends Vue {
  private mounted() {
    var canonicalLink = document.createElement("link");
    // Establece los atributos del enlace

    canonicalLink.rel = "canonical";
    canonicalLink.href = "https://muniarica.cl/municipio/concejo";
    document.head.appendChild(canonicalLink);
  }
}
