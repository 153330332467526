var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row col-12 color_gris justify-center q-py-lg"},[_c('div',{staticClass:"\n      row\n      justify-between\n      items-center\n      col-12 col-md-10\n      q-py-sm\n      text-black\n    "},[_vm._m(0),_c('div',{staticClass:"col-12 row"},[_c('img',{staticClass:"full-width sm-hide md-hide lg-hide xl-hide",style:(_vm.$q.screen.xs ? '' : 'border-radius: 20px !important'),attrs:{"src":require("@/assets/concejo/concejo2.webp")}}),_c('img',{staticClass:"full-width xs-hide q-px-sm",style:(_vm.$q.screen.xs ? '' : 'border-radius: 20px !important'),attrs:{"src":require("@/assets/concejo/concejocap.webp")}})]),_c('div',{class:'row col-12  ' +
          (_vm.$q.screen.xs || _vm.$q.screen.sm || _vm.$q.screen.md
            ? 'justify-center q-mt-sm'
            : 'justify-between q-mt-xl')},_vm._l((_vm.listaConcejales),function(item,index){return _c('div',{key:index,staticClass:"row bg-white q-my-md",style:('border-radius: 20px;' +
            (_vm.$q.screen.xs || _vm.$q.screen.sm || _vm.$q.screen.md
              ? 'width: 95%; '
              : 'width: 48%; '))},[_c('div',{staticClass:"col-xs-12 col-sm-4",style:('overflow:hidden;' +
              (_vm.$q.screen.xs
                ? 'padding:25px;'
                : 'border-radius: 20px 0 0px 20px'))},[_c('img',{staticClass:"img-fluid",style:(_vm.$q.screen.xs ? 'border-radius: 20px 20px 0 0;' : ''),attrs:{"src":item.foto}})]),_c('div',{class:'col-xs-12 col-sm-7 full-height ' +
              (_vm.$q.screen.xs ? 'q-px-lg q-pb-lg ' : 'q-pa-md  ')},[_c('p',{staticClass:"text-secondary text-bold q-mb-sm",staticStyle:{"font-size":"18px","height":"50px"}},[_vm._v(" "+_vm._s(item.nombre)+" ")]),_c('div',{staticClass:"q-mb-sm"},[_c('q-icon',{staticClass:"q-mr-sm",attrs:{"size":"sm","name":"email","color":"secondary"}}),_c('span',{staticStyle:{"overflow-wrap":"break-word"}},[_vm._v(_vm._s(item.email))])],1),_c('div',[_c('q-icon',{staticClass:"q-mr-sm",attrs:{"size":"sm","name":"phone","color":"secondary"}}),_c('span',[_vm._v(" "+_vm._s(item.telefono))])],1),(item.nombreAsistente)?_c('div',[_c('q-separator',{staticClass:"q-my-md"}),_c('p',{staticClass:"text-primary text-bold q-mb-sm"},[_vm._v("ASISTENTE:")]),_c('div',{staticClass:"q-mb-sm"},[_c('q-icon',{staticClass:"q-mr-sm",attrs:{"size":"sm","name":"person","color":"secondary"}}),_c('span',{staticClass:"text-bold"},[_vm._v(" "+_vm._s(item.nombreAsistente))])],1),_c('div',[_c('q-icon',{staticClass:"q-mr-sm",attrs:{"size":"sm","name":"phone","color":"secondary"}}),_c('span',[_vm._v(" "+_vm._s(item.telefonoAsistente))])],1)],1):_vm._e()])])}),0)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"q-mb-lg q-px-sm"},[_c('label',{staticClass:"text-secondary text-h4",staticStyle:{"font-weight":"700"}},[_vm._v(" Concejo municipal ")])])}]

export { render, staticRenderFns }